@import '../../assets/styles/colors';

.timerPage {
  -webkit-transition: all 1s; /* For Safari 3.1 to 6.0 */
  transition: all 1s;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 8vh;
    padding: 0 1rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 92vh;
  }
}

.tournament {
  margin: 0;
  font-size: 5vw;
  width: max-content;
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 150px;
  text-align: left;
  overflow: hidden;
  -webkit-transition: all 1s; /* For Safari 3.1 to 6.0 */
  transition: all 1s;

  &__theme {
    margin-top: 3rem;
    width: 100%;
    text-align: center;
  }
 }

.icon {
  transform: scale(1.5);
}

.menu-icon {
  z-index: 100;
}

.menu-open {
  opacity: 1;
  z-index: 99;
}

.menu-close {
  width: 0;
  opacity: 0;
}
