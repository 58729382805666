.big {
  font-size: 25.5vw;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small {
  font-size: 2rem;
  text-align: center;
}
