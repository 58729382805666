@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import 'assets/styles/colors';

body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background-color: '#000000';
    color: $black;
    height: 100vh;
}

p,
h1 {
    margin: 0;
}

#root {
    height: 100%;
}

a:-webkit-any-link {
    text-decoration: none;
}
