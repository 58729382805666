@import '../../assets/styles/colors.scss';

.Login {
    background: $white;
    padding: 1rem 3rem;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;

    &__logo {
        width: 80%;
    }

    &__row {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        width: 100%;
        height: 40px;

        &__icon {
            background: $black;
            height: 100%;
            width: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .icon {
        color: $white;
        transform: scale(1.3);
    }

    &__input {
        width: 85%;
        height: 100%;
        border: none;
        text-align: center;
        background: $lightgray;
        font-family: 'Roboto', sans-serif;
    }

    &__input::-webkit-input-placeholder {
        /* Edge */
        color: $gray;
        font-weight: bold;
    }

    &__input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $gray;
        font-weight: bold;
    }

    &__input::placeholder {
        color: $gray;
        font-weight: bold;
    }

    &__button {
        background: $blue;
        margin-top: 1rem;
        color: $white;
        font-weight: bold;
        font-size: 1rem;
        border: none;
        width: 100%;
        padding: 0.5rem 0;
        border-radius: 50px;
    }
}
