@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
body{font-family:'Roboto', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;margin:0;background-color:'#000000';color:#393835;height:100vh}p,h1{margin:0}#root{height:100%}a:-webkit-any-link{text-decoration:none}

.big{font-size:25.5vw;width:100%;display:flex;justify-content:center;align-items:center}.small{font-size:2rem;text-align:center}

.Login{background:#fff;padding:1rem 3rem;border-radius:50px;display:flex;flex-direction:column;align-items:center;max-width:400px}.Login__logo{width:80%}.Login__row{display:flex;align-items:center;margin-top:1rem;width:100%;height:40px}.Login__row__icon{background:#393835;height:100%;width:15%;display:flex;align-items:center;justify-content:center}.Login .icon{color:#fff;-webkit-transform:scale(1.3);transform:scale(1.3)}.Login__input{width:85%;height:100%;border:none;text-align:center;background:#f2f2f2;font-family:'Roboto', sans-serif}.Login__input::-webkit-input-placeholder{color:#706f6d;font-weight:bold}.Login__input:-ms-input-placeholder{color:#706f6d;font-weight:bold}.Login__input::-moz-placeholder{color:#706f6d;font-weight:bold}.Login__input::-ms-input-placeholder{color:#706f6d;font-weight:bold}.Login__input::placeholder{color:#706f6d;font-weight:bold}.Login__button{background:#125281;margin-top:1rem;color:#fff;font-weight:bold;font-size:1rem;border:none;width:100%;padding:0.5rem 0;border-radius:50px}

.timerPage{-webkit-transition:all 1s;transition:all 1s}.timerPage__header{display:flex;align-items:center;justify-content:space-between;height:8vh;padding:0 1rem}.timerPage__body{display:flex;flex-direction:column;align-items:center;justify-content:center;height:92vh}.tournament{margin:0;font-size:5vw;width:-webkit-max-content;width:-moz-max-content;width:max-content}.menu{position:absolute;top:0;right:0;height:100%;width:150px;text-align:left;overflow:hidden;-webkit-transition:all 1s;transition:all 1s}.menu__theme{margin-top:3rem;width:100%;text-align:center}.icon{-webkit-transform:scale(1.5);transform:scale(1.5)}.menu-icon{z-index:100}.menu-open{opacity:1;z-index:99}.menu-close{width:0;opacity:0}

.Main{padding:5vh 7vw 0 7vw;display:flex;justify-content:center;align-items:center}

